<script>
export default {
  name: 'index'
}
</script>

<template>
  <div class="index-cgu">
    <div class="begin">
      <div class="content p-4 lg:p-8">
        <div class="header">
          <div class="flex items-center justify-center">
            <svg-icon
              name="ic_logo"
              original
              class="w-1/3 md:w-1/4 xl:w-1/12"
            />
          </div>
        </div>
        <br />

        <div class="body">
          <h1>Conditions Générales d’Utilisation de la plateforme</h1>

          <h2>Table des matières</h2>
          <ol class="list-decimal list-inside">
            <li><a href="#mentions-legales">Mentions légales</a></li>
            <li><a href="#creation-de-compte">Création de compte</a></li>
            <li><a href="#services-de-lapplication">Services de l’Application</a></li>
            <li><a href="#conditions-generales-de-ventes">Conditions Générales de Ventes (CGV)</a></li>
            <li><a href="#limitation-contractuelle">Limitation contractuelle</a></li>
            <li><a href="#propriete-intellectuelle">Propriété intellectuelle</a></li>
            <li><a href="#responsabilite-de-lediteur">Responsabilité de l’Editeur</a></li>
            <li><a href="#publications-par-lutilisateur">Publications par l’Utilisateur</a></li>
            <li><a href="#engagements-de-lutilisateur">Engagements de l’Utilisateur</a></li>
            <li><a href="#litiges">Litiges</a></li>
            <li><a href="#retrait-et-contact">Retrait et contact</a></li>
          </ol>

          <h2 id="mentions-legales">1. Mentions légales</h2>
          <p>Conformément aux articles 328 et 415 de la loi n° 2020 - 35 du 06 janvier 2021 modifiant la loi n° 2017-20 du 20 avril 2018 portant Code du numérique en République du Bénin, nous vous informons que « CareX » est une plateforme digitale mise en œuvre au Bénin par l’Agence Belge de Développement (Enabel) dans le cadre du Programme d’appui à la santé sexuelle et reproductive et à l’information sanitaire (P@sris) en partenariat avec l’Agence Béninoise pour la Promotion de la Famille (ABPF).</p>
          <p><strong>Enabel/P@sris</strong> est situé dans l’enceinte du Ministère de la santé à Akpakpa PK3 route de Porto-Novo ; IFU : 5201910991501 ; représenté par Dr Yassinmè Elysée SOMASSE, en qualité d’Intervention Manager ; <strong>L’ABPF</strong> est située à Vèdoko rue 2470 c/471 Cotonou Immeuble AHOSSI.</p>
          <p>Ci-après dénommé « l’Editeur ».</p>
          <p><strong>Le Cabinet 41 Devs</strong> est le développeur de l’application digitale « CareX » (ci-après, « Application mobile ») de service de Santé Sexuelle et Reproductive dédiée aux jeunes et adolescents. L’Application donne accès à plusieurs informations et services SSR, de programme ou de données (ci-après, « Contenu ») appartenant à « l’Editeur ».</p>
          <p>Ci-après dénommé « le Développeur ».</p>
          <p>L’accès et/ou l’utilisation de cette Application par toute personne physique confère à cette personne, la qualité d’utilisateur (ci-après, « l’Utilisateur »). Les présentes conditions générales d’utilisation, ci-après « CGU » régissent votre relation avec l'Application exploitée par l’Association Béninoise pour la Promotion de la Famille (ABPF). Veuillez les lire attentivement avant d’utiliser l’Application. Les « CGU » sont accessibles sur l’Application dès la première utilisation et reste disponibles tout le temps sur la page d’accueil à travers le lien « Condition d’Utilisation ».</p>
          <p>Toute inscription ou utilisation de l’Application implique l'acceptation sans aucune réserve ni restriction des présentes « CGU » par l’Utilisateur.</p>
          <p>En cas de non-acceptation des « CGU », « l'Utilisateur » se doit de renoncer à l'accès des services proposés par l’application. La violation de l’une quelconque des clauses contenues dans les présentes « CGU » peut entraîner l’interruption de la fourniture du Contenu par « l’Editeur ».</p>
          <p>Conformément à l’article 405 de la loi n° 2020 - 35 du 06 janvier 2021 modifiant la loi n° 2017-20 du 20 avril 2018 portant Code du numérique en République du Bénin, l’Application d’ores et déjà fait l'objet d'une déclaration auprès de l’Autorité de Protection des Données Personnelles (APDP).</p>

          <h2 id="creation-de-compte">2. Création de compte</h2>
          <p>Lors de la création de son compte, « l’Utilisateur » s’engage à fournir à tout moment des informations exactes, complètes et à jour jugées nécessaires pour l’utilisation de l’application, notamment son nom et prénoms, son numéro de téléphone et son mois/année de naissance. La plateforme « CareX » est ouverte à tout Utilisateur dont l’âge est compris entre 10 et 24 ans.</p>
          <p>En tout état de cause, « l’Utilisateur » s’interdit d’utiliser comme nom d'utilisateur le nom d'une autre personne ou entité ou qui n'est pas légalement disponible pour utilisation, un nom ou une marque qui est soumis à des droits d'une autre personne ou entité autre que vous sans autorisation appropriée, ou un nom qui est autrement offensant, vulgaire ou obscène.</p>
          <p>Toutefois, il est permis à « l’Utilisateur » de s’inscrire sur la plateforme avec un pseudonyme afin de lui garantir un certain anonymat.</p>
          <p>« L’Utilisateur » peut se désinscrire de la plateforme en se rendant à la rubrique dédiée et cliquer sur « Se désabonner ». Celle-ci sera effective dans un délai raisonnable tel que mentionné dans la « Politique de confidentialité des données ».</p>
          <p>« L’Utilisateur » est responsable du maintien de la confidentialité de son compte. « L’Editeur » n’est pas responsable de tout dommage ou perte pouvant résulter d’une erreur de sa part dans la protection de ses informations d’accès, y compris son mot de passe. « L’Utilisateur » s’oblige à nous informer immédiatement dès qu’il a connaissance d'une violation de la sécurité ou d'une utilisation non autorisée de son compte.</p>
          <p>« L’Utilisateur » s’engage à faire un usage approprié du Contenu de la plateforme que lui offre « l’Editeur ».</p>
          <p>« L’Utilisateur » s’interdit notamment : d’utiliser le Contenu pour (i) s’engager dans des activités illégales ou contraires à la bonne foi et à l’ordre public; (ii) diffuser du contenu ou de la propagande de nature raciste, xénophobe, pornographique et illégale, prôner le terrorisme ou attaquer les droits de l’homme; (iii) causer des dommages aux systèmes de « l’Editeur » (iv) essayer d’accéder et, le cas échéant, utiliser les comptes de courrier électronique d’autres utilisateurs et modifier ou manipuler leurs messages.</p>

          <h2 id="services-de-lapplication">3. Services de l’Application</h2>
          <p>L’application est destinée aux :</p>
          <ul class="list-disc list-inside">
            <li>Jeunes et adolescents de tous genres.</li>
            <li>Aux professionnels de Santé Sexuelle et Reproductive (SSR) agréés et dont l’inscription est validée au préalable.</li>
          </ul>
          <p>« CareX » est une application mobile de services sur la Santé Sexuelle et Reproductive. Elle permet à l'Utilisateur un accès gratuit aux fonctionnalités suivantes :</p>
          <ul class="list-disc list-inside">
            <li>Discussion instantanée avec des professionnels SSR</li>
            <li>Evaluation de ses connaissances à travers des Quiz-tests</li>
            <li>Localisation des points SSR de prise en charge</li>
            <li>Accès aux « Articles et Astuces » sur des thématiques SSR</li>
            <li>Outil de suivi du Cycle menstruel</li>
            <li>Conseils sur les Contraceptions et les thématiques SSR.</li>
          </ul>
          <p>Cette liste est non exhaustive et peut être modifiée à tout moment par « l’Editeur » sans que sa responsabilité ne puisse être engagée à ce titre par qui que ce soit. L’application est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet.</p>
          <p>« L’Utilisateur » accepte que « l’Editeur » puisse ajouter, supprimer ou modifier des fonctionnalités de l’Application. Toutes les fonctionnalités seront soumises aux mêmes conditions d’utilisation.</p>
          <p>« L’Editeur » peut recueillir des suggestions de la part de « l’Utilisateur », mais en aucun cas il ne s’engage à ajouter, modifier ou éliminer ces fonctionnalités.</p>
          <p>L’Application est téléchargeable gratuitement depuis les plateformes « Apple Store » et « Google Play Store » sur tout terminal utilisant les systèmes d’exploitation les plus récents et disponibles sur le marché des systèmes d’exploitation.</p>

          <h2 id="conditions-generales-de-ventes">4. Conditions Générales de Ventes (CGV)</h2>
          <p>Les services fournis par l’application « CareX » sont entièrement gratuits. A ce titre, les Utilisateurs peuvent réaliser toutes les actions disponibles sur la plateforme sans offrir une contrepartie à un prestataire, notamment dans le cadre d’une discussion avec un agent de santé, pour la recherche des Points SSR partenaires et dans le suivi de son cycle menstruel.</p>
          <p>Par ailleurs, certains services de prise en charge dans les Points SSR partenaires sont payants et le cas échéant sont entièrement à la charge du patient. Tout contrat de prestation de service se conclut entre « l’Utilisateur » et l’agent de santé/le Point SSR partenaires, tandis que tout contact est pris avec « l’Editeur » par le biais de l’application « CareX ».</p>
          <p>La mise à disposition des Services intervient automatiquement après activation et validation du compte de « l’Utilisateur » sur l’application « CareX ». « L’Editeur » décline toute responsabilité relative aux différends qui découleront des consultations des Utilisateurs dans un centre de santé partenaire.</p>

          <h2 id="limitation-contractuelle">5. Limitation contractuelle</h2>
          <p>L’Application peut être mise à jour à différentes périodes de l’année. Les informations qu’elle contient sont aussi précises que possible. Toutefois, l’application peut contenir des inexactitudes ou des omissions. Si vous constatez une lacune, erreur ou ce qui parait être un dysfonctionnement, merci de bien vouloir le signaler par email, à l’adresse : <a href="mailto:abpf@abpf.org" target="_blank">abpf@abpf.org</a>, en décrivant le problème de la manière la plus précise possible (page posant problème, …..Etc.).</p>
          <p>Tout évènement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement de l’application ou serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage pas la responsabilité de « l’Editeur ». Dans ces cas, « l’Utilisateur » accepte ainsi ne pas tenir rigueur à l’Editeur de toute interruption ou suspension de service, même sans préavis.</p>

          <h2 id="propriete-intellectuelle">6. Propriété intellectuelle</h2>
          <p>Enabel/P@sris, est titulaire de tous les droits de propriété intellectuelle relatifs à l’application « CareX ».</p>
          <p>Les marques, logos, signes ainsi que tous les contenus de l’application (textes, images, …) font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur.</p>
          <p>« L'Utilisateur » doit solliciter l'autorisation préalable du promoteur de l’application pour toute reproduction, publication, copie des différents contenus. Il s'engage à une utilisation des contenus de l’application dans un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite.</p>
          <p>Toute représentation totale ou partielle de cette application par quelque procédé que ce soit, sans l’autorisation expresse de « l’Editeur » de l’application constituerait une contrefaçon sanctionnée par le Code de la propriété intellectuelle.</p>

          <h2 id="responsabilite-de-lediteur">7. Responsabilité de « l’Editeur »</h2>
          <p>« L’Editeur » ne peut être engagé en cas de défaillance, panne, difficulté ou interruption de fonctionnement, ou même empêchant l’accès à l’application ou à l’une de ses fonctionnalités. Le matériel de connexion à l’application que vous utilisez est sous votre entière responsabilité. Vous devez prendre toutes les mesures appropriées pour protéger votre matériel et vos propres données notamment d’attaques virales par Internet. « L’Editeur » ne pourra être tenu responsable en cas de poursuites judiciaires à votre encontre :</p>
          <ul class="list-disc list-inside">
            <li>du fait de l’usage de l’application ;</li>
            <li>du fait du non-respect par vous des présentes Conditions Générales.</li>
          </ul>
          <p>« L’Editeur » n’est pas responsable des dommages causés à vous-même, à des tiers et/ou à votre équipement du fait de votre connexion ou de votre utilisation de l’Application.</p>
          <p>Si « l’Editeur » venait à faire l’objet d’une procédure amiable ou judiciaire à raison de votre utilisation de l’Application, il pourrait se retourner contre vous pour obtenir indemnisation de tous les préjudices, sommes, condamnations et frais qui pourraient découler de cette procédure.</p>

          <h2 id="publications-par-lutilisateur">8. Publications par « l’Utilisateur »</h2>
          <p>L’Application offre un espace d’échanges appelée « Forum de discussion ». Dans cet espace, « l’Editeur » se réserve le droit de retirer tous les commentaires et contributions qui violent le respect de la dignité de la personne, qui sont discriminatoires, xénophobes, racistes, pornographiques, contre la jeunesse ou l’enfance, l’ordre ou la sécurité publique ou qui, à son avis, ne sont pas adaptés à la publication.</p>
          <p>Tout contenu mis en ligne par « l'Utilisateur » est de sa seule responsabilité. « L'Utilisateur » s'engage à ne pas mettre en ligne de contenus pouvant porter atteinte aux intérêts de tierces personnes. Tout recours en justice engagé par un tiers lésé contre l’Application sera pris en charge par « l'Utilisateur ».</p>
          <p>Le contenu de « l'Utilisateur » peut être supprimé à tout moment et pour n'importe quelle raison par le responsable de l’application, sans préavis.</p>
          <p>Dans tous les cas, « l’Editeur » ne sera pas responsable des opinions exprimées par les Utilisateurs à travers les forums et les chats.</p>

          <h2 id="engagements-de-lutilisateur">9. Engagements de « l’Utilisateur »</h2>
          <p>« L’Utilisateur » s’engage expressément :</p>
          <ul class="list-disc list-inside">
            <li>à ne pas reproduire de façon permanente ou provisoire l’Application, en tout ou partie, par tout moyen et sous toute forme ;</li>
            <li>à ne pas utiliser de logiciels ou de procédés destinés à copier le contenu sans l'autorisation préalable écrite de l’éditeur ;</li>
            <li>à ne pas procéder à toute adaptation, modification, traduction, transcription, arrangement, compilation, décompilation, assemblage, désassemblage, transcodage, ni appliquer la rétro-ingénierie (ou « Reverse Engineering ») de tout ou partie de l’Application ;</li>
            <li>à ne pas exporter l’Application, fusionner tout ou partie de l’Application avec d'autres programmes informatiques ;</li>
            <li>à ne procéder à des courtes citations, analyses et reproductions destinées à des revues de presse ainsi qu'aux autres utilisations expressément autorisées par la loi dans les limites et conditions fixées par cette dernière et sous réserve notamment de citer le nom des auteurs et la source éditoriale ;</li>
            <li>à renoncer expressément à utiliser des logiciels ou dispositifs susceptibles de perturber le bon fonctionnement de l’Application, ni à engager d'action de nature à imposer une charge disproportionnée pour les infrastructures de « L’Editeur » ;</li>
            <li>à ne pas extraire ou réutiliser, y compris à des fins privées, sans autorisation préalable écrite d’une partie substantielle ou non du contenu des bases de données et archives constituées par l’Application ;</li>
            <li>à ne pas mettre en place des systèmes susceptibles de pirater l’Application et/ou le contenu en tout ou partie, ou de nature à violer les présentes « CGU » ;</li>
            <li>à informer « L’Editeur » dès la connaissance d'un acte de piratage et en particulier de toute utilisation illicite ou non contractuelle de l’Application et/ou du contenu quel que soit le mode de diffusion ;</li>
            <li>ne pas vendre, louer, sous-licencier ou distribuer de quelque façon que ce soit l’Application.</li>
          </ul>

          <h2 id="litiges">10. Litiges</h2>
          <p>Les présentes Conditions Générales d’Utilisations de l’Application sont régies par les lois Béninoises et toutes contestations ou litiges qui pourraient naître de l’interprétation ou de l’exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend le siège social de « l’Editeur ». La langue de référence, pour le règlement de contentieux éventuels, est le français.</p>

          <h2 id="retrait-et-contact">11. Retrait et contact</h2>
          <p>« L’Editeur » peut décider de retirer l’Application des marchés d’applications à tout moment sans préavis. La société peut également faire cesser de façon temporaire ou définitive le fonctionnement de l’Application. Toutefois, en cas de retrait, les droits fondamentaux des utilisateurs vis-à-vis de leurs données à caractère personnel sont garantis.</p>
          <p>Toute question relative à l’Application et/ou aux Services doit être adressée à son Editeur dont les références sont ci-dessus mentionnées.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/style/sass/variables";
h1, h2 {
  text-align: center;
  color: $pass_sidebar_background;
}
h1 {
  font-size: 2.5em;
  font-weight: bold;
}
h2 {
  font-size: 1.8em;
  font-weight: bold;
}
p {
  margin: 10px 0;
  line-height: 1.6;
}
ul, ol {
  margin: 10px 0 10px 20px;
}
li {
  margin: 5px 0;
}
a {
  color: #1e90ff;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.content {
  max-width: 800px;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
</style>
